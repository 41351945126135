html {
  height: 100%;
  overflow-y: hidden;
}
body,
#root {
  min-height: 100vh;
  overflow-y: hidden;
  background-color: #292a2d;
}

body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* CSS for Mosaic Tiles */
#app {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

.app-container {
  height: 100vh;
  width: 100%;
  padding: 0;
}

.header {
  height: 50px;
}

.main {
  padding-top: 5px;
}
/** End - CSS for Mosaic Tiles  */

.headerLogo {
  /* background-image: url("/images/logo.png"); */
  background-repeat: no-repeat;
}

/** Header **/
.supportPhone {
  cursor: pointer;
  font-size: "10px";
  color: #000000;
  text-decoration: none;
}

.supportPhone:hover {
  text-decoration: underline;
}

/* .viewerMenuBar {
  background-color: #292a2d !important;
  color: #ffffff;
  width: 100%;
} */

.MuiButtonGroup-root {
  color: #ffffff;
}

.MuiButton-root {
  color: #ffffff;
  background-color: #af1730;
}

.MuiLoadingButton-root:not([disabled]) {
  color: #ffffff !important;
  background-color: #af1730 !important;
}

.MuiTextField-root {
  color: #ffffff !important;
}
.MuiSelect-root {
  color: #ffffff !important;
}

#zmmtg-root {
  display: none;
}

.zm-btn__outline--blue {
  border-color: #af1730 !important;
}

/* Remove login email input outline when focused */
input#email:focus,
input#firstName:focus,
input#lastName:focus,
input#email:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
}

input#email {
  transition: none !important;
}

/* Remove blue outline from inputs when focused */
input[type="text"]:focus,
input[type="text"]:focus-visible,
input#name:focus,
input#name:focus-visible,
input#recipients:focus,
input#recipients:focus-visible,
input#exhibitNumber:focus,
input#exhibitNumber:focus-visible,
div[class*="MuiInputBase-root"] input[class*="MuiInputBase-input"]:focus,
div[class*="MuiInputBase-root"] input[class*="MuiInputBase-input"]:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
}

input[type="text"],
input#name,
input#recipients,
input#exhibitNumber,
div[class*="MuiInput-formControl"] input[class*="MuiInput-input"] {
  transition: none !important;
  outline: none !important;
  outline-offset: 0 !important;
}

.MuiDataGrid-row.Mui-selected {
  background-color: rgb(175, 23, 48, 0.08) !important;
}

/** Remove blue outline on buttons **/
.MuiButtonBase-root:focus,
.MuiButtonBase-root:focus-within {
  /* outline: solid #af1730 1px !important; */
  outline: none !important;
}

/** Remove blue outline on menus **/
.MuiMenu-paper,
.MuiBox-root,
.MuiList-root {
  outline: none !important;
}

/** Remove blue outline on the DataGrid header and cells **/
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  /* outline: solid #af1730 1px !important; */
  outline: none !important;
}

.MuiDataGrid-cell:hover,
.MuiDataGrid-cell a:focus,
.MuiDataGrid-cell a:hover,
.MuiTreeItem-iconContainer svg {
  color: #af1730 !important;
}

form div[class^="MuiDialogContent-root"] {
  overflow-x: hidden;
}

/* Styles to keep consistency with Huseby-Client-Portal  */
div[class*="Mui-selected"],
li[class*="Mui-selected"] {
  background-color: rgba(175, 23, 48, 0.08) !important;
}

div[class*="MuiDataGrid-columnSeparator"] {
  color: rgba(224, 224, 224, 1) !important;
}

a[class*="MuiLink-underlineHover"]:hover {
  color: rgba(175, 23, 48, 1) !important;
}

span[class*="MuiCheckbox-colorPrimary"]:not([disabled])::before {
  color: rgba(175, 23, 48, 1) !important;
}

span[class*="search"] {
  align-self: center;
}

/* This is necessary for sticky headers in TranscriptViewer with react-virtualized. */
.ReactVirtualized__Table__headerRow {
  position: sticky;
  inset-block-start: 0;
  z-index: 1;
}
