/* Mosaic CSS*/
.mosaic {
  background-color: #494a4b !important;
  border: 0px;
}
.mosaic-window .mosaic-preview,
.mosaic-preview .mosaic-preview {
  z-index: -1 !important;
}

.mosaic-window .mosaic-window-toolbar.draggable {
  z-index: 0;
}

/* Transcript  */
.mosaic-window-body > div {
  margin: 0 !important;
  overflow-y: hidden !important;
}
