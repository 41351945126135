.header-button,
.menu-button {
  color: #ffffff !important;
}

.header-container {
  background-color: #af1730;
  height: 55px;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px;
  padding: 10px;
}

.divider {
  background: #eeeeee;
  margin: 10px;
}
